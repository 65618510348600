<template>
  <v-container class="pt-15">
    <div class="text-sm-h3 text-h4 font-weight-bold text-center">Nuestros productos</div>
    <div class="d-flex justify-center align-center my-2">
      <div><v-divider :width="($vuetify.breakpoint.sm)?200:100" class="primary"></v-divider></div>
      <div class="text-center mx-5 primary--text">o</div>
      <div><v-divider :width="($vuetify.breakpoint.sm)?200:100" class="primary"></v-divider></div>
    </div>

    <v-row class="pt-10">
      <!-- item col -->
      <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in listProds" :key="index">
        <v-card>
          <!-- image -->
          <v-hover v-slot="{ hover }">
            <v-card @click="openDetails(item.id)" tile elevation="0">
              <div class="pa-5" style="position:relative">
                <v-img
                  :src="assets+item.image"
                  :aspect-ratio="16/12"
                  contain
                >
                </v-img>
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                    style="height: 100%;"
                  >
                    <v-icon :x-large="$vuetify.breakpoint.smAndUp">mdi-eye</v-icon>
                  </div>
                </v-expand-transition>
              </div>
            </v-card>
          </v-hover>
          <!-- title -->
          <v-card-title>
            <v-list-item-title class="text-h6">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="grey--text text--darken-1">{{ item.category.name }}</v-list-item-subtitle>
          </v-card-title>
          <!-- actions -->
          <v-card-actions>
            <span>{{ item.sale_price }}/<small>Bs</small></span>
            <v-spacer></v-spacer>
            <v-btn
              color="#25d366"
              outlined
              target="_blank"
              :href="`https://web.whatsapp.com/send?phone=591${phoneWsp}&text=Hola%2C+quisiera+comprar+este+producto%3A%0D%0A%0D%0A*${item.name}*%0A*Precio:*%20${item.sale_price} Bs.%0D%0A%0D%0AGracias.`"
            >
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="addToCart({id: item.id, name: item.name, category: item.category.name, price: item.sale_price, img: assets+item.image, quantity: 1})"
              :loading="item.id == loadId"
            >
              <v-icon>mdi-cart-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- end item col -->

      <v-col cols="12" class="pt-10 text-center">
        <v-btn
          color="primary"
          large
          to="/tienda"
        >
          Ver tienda
          <v-icon right>mdi-store</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- dialog -->
    <v-dialog
      v-model="detailOpen"
      width="900"
    >
      <v-card>
        <v-fab-transition>
          <v-btn
              absolute
              right
              fab
              small
              style="top: 10px;"
              @click="detailOpen=!detailOpen"
          >
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-card min-height="500" class="h-full d-flex align-center card-prod" color="primary" :style="{backgroundImage: `url(${require('../assets/images/table.png')})`}" tile elevation="0">
              <v-card-text>
                <v-img
                  :lazy-src="assets+singleProd.image"
                  :src="assets+singleProd.image"
                  class="mx-auto"
                  contain
                  width="100%"
                  max-height="280px"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="h-full pa-8 d-flex justify-center flex-column" color="blue-grey darken-4" tile elevation="0">
              <v-card-title class="text-h5">
                {{ singleProd.name }}
              </v-card-title>
              <v-card-subtitle class="primary--text text-h4">
                {{ singleProd.sale_price }}/Bs
              </v-card-subtitle>
              <v-card-text class="font-weight-light" v-if="singleProd.category">
                {{ singleProd.category.name }}
              </v-card-text>
              <v-card-text>
                <v-select
                  v-if="itemsComplements.length>0"
                  :items="itemsComplements"
                  outlined
                  label="Variaciones"
                  v-model="complement"
                ></v-select>
                <div class="d-flex align-center">
                  <v-btn color="primary" class="rounded-r-0" @click="quantity-=1" :disabled="quantity<=1">
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                  <v-sheet
                    height="36"
                    width="100"
                    class="sheet-cant"
                  >
                    {{ quantity }}
                  </v-sheet>
                  <v-btn color="primary" class="rounded-l-0" @click="quantity+=1" :disabled="quantity>=stock">
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-text class="mt-auto">
                <v-btn
                  :x-large="$vuetify.breakpoint.smAndUp"
                  block
                  color="orange darken-2"
                  @click="addToCart({id: singleProd.id, name: singleProd.name, category: singleProd.category.name, price: singleProd.sale_price, img: assets+singleProd.image, quantity: quantity, complement: complement})"
                  :loading="loadPop"
                >
                  <v-icon class="mr-5">mdi-cart-outline</v-icon>
                  Añadir al carrito
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      loadId: '',
      loadPop: false,
      detailOpen: false,
      quantity: 1,
      stock: 100,
      complement: '',
      // list products
      listProds: [],
      singleProd: {},
      itemsComplements: [],
      // location
      tmpLat: -19.5888419,
      tmpLng: -65.7538486,
      phoneWsp: null
    }
  },
  computed: {
    assets () {
      return this.$store.state.layout.assets;
    },
  },
  mounted() {
    this.getLocation()
    this.getRemmended()
  },
  methods: {
    /**
     * get location
     */
    getLocation() {
      window.navigator.geolocation.getCurrentPosition(
        (pos)=>{
          const crd = pos.coords;
          this.tmpLat = +crd.latitude
          this.tmpLng = +crd.longitude
        },
        (err)=>{
          console.warn('ERROR(' + err.code + '): ' + err.message)
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });

      setTimeout(() => {
        this.getPhoneStore()
      }, 300);
    },
    // telefono de la tienda
    async getPhoneStore() {
      await axios.post('get-phonestore', {lat: this.tmpLat, lng: this.tmpLng})
      .then((response) => {
        // console.log(response)
        this.phoneWsp = response.data
      })
      .catch((error) => {
        console.log(error)
      })
    },
    /**
     * productos
     */
    async getRemmended() {
      await axios.post('get-recommended', {})
      .then((response) => {
        // console.log(response)
        this.listProds = response.data
      })
      .catch((error) => {
        console.log(error)
      })
    },
    /**
     * add to cart
     */
    addToCart(item) {
      this.loadId = item.id
      this.loadPop = true
      setTimeout(() => {
        this.$store.dispatch('cart/addProduct', item)
        this.loadId = ''
        this.loadPop = false
        this.detailOpen = false
        this.quantity = 1
        this.complement = ''
      }, 500);
    },
    /**
     * open details
     */
    openDetails(id) {
      var tmpProd = this.listProds.filter(item => item.id === id)[0]
      
      this.singleProd = tmpProd
      this.itemsComplements = JSON.parse(tmpProd.complements)
      if (this.itemsComplements.length>0) this.complement = this.itemsComplements[0]
      this.detailOpen = true
    }
  },
}
</script>
<style lang="scss">
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  left: 0;
  width: 100%;
}
</style>