<template>
    <div>

        <!-- main banner -->
        <main-banner></main-banner>
        <!-- end main banner -->

        <!-- marcas -->
        <carousel-marks></carousel-marks>
        <!-- end marcas -->

        <!-- about -->
        <about></about>
        <!-- end about -->

        <!-- tienda -->
        <main-store></main-store>
        <!-- end tienda -->

        <!-- video -->
        <sec-video></sec-video>
        <!-- end video -->

    </div>
</template>

<script>
import MainBanner from '../components/MainBanner.vue'
import CarouselMarks from '../components/CarouselMarks.vue'
import About from '../components/About.vue'
import MainStore from '../components/MainStore.vue'
import SecVideo from '../components/SecVideo.vue'

export default {
    name: 'Home',
    components: {
        MainBanner,
        CarouselMarks,
        About,
        MainStore,
        SecVideo
    },
    data() {
        return {
          
        }
    }
}
</script>
