<template>
  <v-row no-gutters>
    <v-col cols="12" sm="6">
      <v-card class="h-full d-flex align-center" color="primary" tile elevation="0">
        <v-card-text>
          <v-img
            :lazy-src="dataAbout.image"
            :src="dataAbout.image"
            class="mx-auto"
            contain
            width="100%"
            max-height="500px"
          ></v-img>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card class="h-full pa-15 d-flex justify-center flex-column" color="blue-grey darken-4" tile elevation="0">
        <v-card-title class="primary--text text-sm-h3 text-h4 font-weight-bold">
          {{ dataAbout.title }}
        </v-card-title>
        <v-card-text class="text-sm-h5 text-h6">
          {{ dataAbout.description }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      dataAbout: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      await axios.post('get-data/about', {})
      .then((response) => {
        // console.log(response)
        this.dataAbout = JSON.parse(response.data[0].content)
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },
}
</script>