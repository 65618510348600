<template>
  <v-container class="py-15">

    <div class="mb-4">
      <iframe width="100%" :height="($vuetify.breakpoint.smAndUp)?600:320" :src="urlVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <VueSlickCarousel v-bind="settingsSliderSmall" v-if="loading" class="wrap-slide px-10">
      <div v-for="(bg, k) in bgVideos" :key="k" class="item-slide px-3">
        <v-img
          :lazy-src="bg"
          :height="($vuetify.breakpoint.smAndUp)?150:80"
          width="100%"
          :src="bg"
          @click="changeVid(k)"
        ></v-img>
      </div>
      <template v-slot:prevArrow>
        <v-btn fab small class="btn-prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
      </template>
      <template v-slot:nextArrow>
        <v-btn fab small class="btn-next"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </template>
    </VueSlickCarousel>

  </v-container>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import axios from 'axios'
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settingsSliderSmall: {
        "dots": false,
        "arrows": true,
        "infinite": true,
        "autoplay": false,
        "slidesToShow": 4,
        "focusOnSelect": true,
        "responsive": [
            {
            "breakpoint": 767,
            "settings": {
                "slidesToShow": 3,
                "focusOnSelect": true,
            }
            },
            {
            "breakpoint": 550,
            "settings": {
                "slidesToShow": 2,
                "focusOnSelect": true,
            }
            }
        ]
      },
      loading: false,
      linkVideos: [],
      bgVideos: [],
      urlVideo: ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      await axios.post('get-data/video', {})
      .then((response) => {
        console.log(response)
        response.data.forEach(vid => {
          this.bgVideos.push(`https://i.ytimg.com/vi/${vid.content}/maxresdefault.jpg`)
          this.linkVideos.push(`https://www.youtube.com/embed/${vid.content}?rel=0&showinfo=0&controls=0&autoplay=1&autohide=0&loop=1`)
        });
        this.urlVideo = this.linkVideos[0]

        this.loading = true
      })
      .catch((error) => {
        console.log(error)
      })
    },
    changeVid(i) {
      this.urlVideo = this.linkVideos[i]
    }
  },
}
</script>
<style lang="scss">
.wrap-slide {
  position: relative;
  .btn-prev,
  .btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-prev {
    left: 0;
  }
  .btn-next {
    right: 0;
  }
  .item-slide {
    cursor: pointer;
  }
  .slick-track {
    margin-left: auto;
    margin-right: auto;
  }
  // .slick-slide{
  //   border: 2PX solid transparent;
  //   &.slick-active.slick-current {
  //     border-color: #0097a7;
  //   }
  // }
}
</style>