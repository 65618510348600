<template>
  <div class="py-6">
    <v-container>
      <VueSlickCarousel v-bind="settingsSliderSmall" class="fx-slick" v-if="showList">
        <v-card
          v-for="(item, index) in listBrands"
          :key="index"
          class="transparent text-center"
          elevation="0"
          :href="`/tienda?brand=${item.id}`"
        >
          <img :src="assets2+item.image" class="brand-img" />
        </v-card>
      </VueSlickCarousel>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios'
import VueSlickCarousel from 'vue-slick-carousel';
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settingsSliderSmall: {
        "dots": false,
        "arrows": false,
        "infinite": true,
        "autoplay": true,
        "speed": 500,
        "slidesToShow": 6,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
            {
            "breakpoint": 1263,
            "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "initialSlide": 1
            }
            },
            {
            "breakpoint": 959,
            "settings": {
                "slidesToShow": 5,
                "slidesToScroll": 1,
                "initialSlide": 1
            }
            },
            {
            "breakpoint": 859,
            "settings": {
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "initialSlide": 1
            }
            },
            {
            "breakpoint": 690,
            "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "initialSlide": 1
            }
            },
            {
            "breakpoint": 550,
            "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 1,
                "initialSlide": 1
            }
            }
        ]
      },
      showList: false,
      listBrands: []
    }
  },
  computed: {
    assets2 () {
      return this.$store.state.layout.assets2;
    },
  },
  mounted() {
    this.getListBrands()
  },
  methods: {
    async getListBrands() {
      await axios.post('brands-lists', {})
      .then((response) => {
        // console.log(response)
        this.listBrands = response.data
        this.showList = true
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },
}
</script>
<style lang="scss">
.brand-img {
  width: 100%;
  max-width: 120px;
  filter: grayscale(1);
  @media(max-width:767px) {
    max-width: 100px;
  }
}
</style>