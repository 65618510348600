<template>
  <v-carousel
    hide-delimiter-background
    show-arrows-on-hover
    hide-delimiters
    :height="($vuetify.breakpoint.smAndUp)?800:500"
    interval="5000"
    cycle
  >
    <template v-for="(banner, i) in dataBanner">  
      <v-carousel-item
        :key="i"
        :src="banner.image"
      >
        <v-container class="py-15 d-flex align-center h-full">
          <v-row>
            <v-col
              cols="12" 
              :md="(banner.position=='center')?'8':'6'"
              :offset-md="(banner.position=='center')?'2':''"
              :class="`text-${banner.position}`"
            >
              <h1 class="text-sm-h2 text-h3 mb-6 font-weight-medium">{{ banner.title }}</h1>
              <p v-if="banner.description" class="text-sm-h5 text-h6 mb-10">{{ banner.description }}</p>
              <v-btn
                v-if="banner.button.text"
                :x-large="$vuetify.breakpoint.smAndUp"
                color="primary"
                :href="banner.button.link"
                :target="(banner.button.openNewTab)?'_blank':'_self'"
              >
                {{ banner.button.text }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </template>
  </v-carousel>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      dataBanner: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      await axios.post('get-data/banner', {})
      .then((response) => {
        // console.log(response)
        response.data.forEach(element => {
          this.dataBanner.push(JSON.parse(element.content))
        });
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },
}
</script>